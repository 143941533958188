import React, { useState } from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Cell = (props) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const {
    value,
    customComponent,
    chevron,
    button,
    header,
    dollar,
    percent,
    onClick,
  } = props;

  const round = (d) => {
    if (dollar || percent) {
      return parseFloat(parseFloat(d).toFixed(2));
    }
    return d;
  };

  const textColor = () => {
    if (percent) {
      return value >= 0 ? theme.colors.green : theme.colors.red;
    }

    return '';
  };

  const hoverColor = () => {
    if (header || (value === null && !customComponent)) {
      return '';
    }

    if (button) {
      return theme.colors.addLevel;
    }

    return theme.colors.addLevel;
  };

  const activeColor = () => {
    if (header || (value === null && !customComponent)) {
      return '';
    }

    if (button) {
      return theme.colors.addHalfLevel;
    }

    return theme.colors.addLevel;
  };


  return (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      roll="button"
      onKeyPress={() => {}}
      onClick={() => {
        setOpen(!open);
        onClick();
      }}
      css={css`
          color: ${textColor()};

          margin: 0px; 
          padding: 0px;
  
          //box-shadow: 0 0 2px rgba(0,0,0,0.2);
          //border: 1px solid #ddd;
          //border-width: 1px;
          //border-style: none none solid none;
          border-radius: 2px;
  
          //height: 36px;
          //width: 82px;
          flex: 1 1 82px;
  
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
  
          text-align: center;
          white-space: wrap;
          font-size: 1vw;
          overflow: hidden;
  
          /* :not(:first-of-type) {
              border-left: 1px solid rgba(0,0,0,0.2);
          }  */
          cursor: ${button ? 'pointer' : 'default'};
          :hover {
            background: ${hoverColor()};
          }
          :active {
            background: ${activeColor()};
          }

          > input, > button {
              background: transparent;
              flex: 1;
              width: 100%;
              border: 0;
              text-align: 'center';
              
              margin: 0;
              padding: 0;

              color: ${theme.colors.text};
              /* ::placeholder {
                  color: ${theme.colors.text2};
              } */
              :focus::placeholder {
                color: transparent;
              }
              :focus {
                outline: none;
                background: ${theme.colors.addLevel}
              }
          }
        `}
    >

      {value
      && (
      <div css={{ margin: '4px' }}>
        {dollar && '$'}
        {round(value).toLocaleString()}
        {percent && '%'}
      </div>
      )}

      {customComponent && customComponent}

      {chevron
      && (
      <div css={css`
        position: absolute;
        bottom: 4px;
        color: rgba(255,255,255,0.3);
        font-size: 0.65vw;
      `}
      >
        {open ? <FaChevronUp /> : <FaChevronDown />}
      </div>
      )}

    </div>
  );
};

Cell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customComponent: PropTypes.node,
  chevron: PropTypes.bool,
  button: PropTypes.bool,
  header: PropTypes.bool,
  dollar: PropTypes.bool,
  percent: PropTypes.bool,
  onClick: PropTypes.func,
};

Cell.defaultProps = {
  value: null,
  customComponent: null,
  chevron: false,
  button: false,
  header: false,
  dollar: false,
  percent: false,
  onClick: () => {},
};

export default Cell;
