import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import { FaPlusCircle } from 'react-icons/fa';
import Cell from './Cell';
import { Row } from './Rows';
import { rowDataGenerator, numberCheck } from '../helpers';
import PortfolioRow from './PortfolioRow';

const DividendSheet = (props) => {
  const theme = useTheme();

  const [newSymbol, setNewSymbol] = useState('');
  const [newQuantity, setNewQuantity] = useState('');
  const [newPrice, setNewPrice] = useState('');

  const { headers, portfolio, stocks } = props;

  const renderHeaders = () => (
    <Row header>
      {headers.map((s) => <Cell header key={`header-${s}`} value={s} />)}
    </Row>
  );

  const renderAddNew = (flex) => (
    <Row css={css`  
        flex: ${flex ? 1 : ''};
        background: ${theme.colors.addLevel};
      `}
    >
      <form css={{ flex: 1, display: 'flex' }}>
        <Cell customComponent={(
          <input
            type="text"
            css={{
              background: 'transparent',
              flex: 1,
              width: '100%',
              border: 0,
              textAlign: 'center',
            }}
            placeholder="Ticker"
            value={newSymbol}
            onChange={(e) => {
              const { value } = e.target;
              setNewSymbol(value.toUpperCase());
            }}
          />
              )}
        />
        <Cell customComponent={(
          <input
            type="number"
            css={{
              background: 'transparent',
              flex: 1,
              width: '100%',
              border: 0,
              textAlign: 'center',
            }}
            placeholder="#"
            value={newQuantity}
            min={0}
            onChange={(e) => {
              const { value } = e.target;
              const num = numberCheck(value);
              setNewQuantity(num);
            }}
          />
              )}
        />
        <Cell customComponent={(
          <input
            type="number"
            css={{
              background: 'transparent',
              flex: 1,
              width: '100%',
              border: 0,
              textAlign: 'center',
            }}
            placeholder="$"
            value={newPrice}
            min={0}
            onChange={(e) => {
              const { value } = e.target;
              const num = numberCheck(value);
              setNewPrice(num);
            }}
          />
              )}
        />
        <Cell
          button
          customComponent={(
            <button
              type="submit"
              placeholder="$"
              onClick={(e) => {
                e.preventDefault();
                if (newSymbol !== '' && newPrice !== '' && newQuantity !== '') {
                  props.addPortfolioData(newSymbol, newPrice, newQuantity);
                  setNewSymbol('');
                  setNewPrice('');
                  setNewQuantity('');
                }
              }}
            >
              <FaPlusCircle size="1.25vw" color={theme.colors.green} />
            </button>
              )}
        />
      </form>
    </Row>
  );

  const renderPortfolio = () => {
    const { rows, totals } = rowDataGenerator(portfolio, stocks);
    const allRows = rows.map((row) => (
      <div
        key={`${row.totals.symbol}-column`}
        css={css`
            display: flex; 
            flex-direction: column; 
            border-bottom: 1px solid ${theme.colors.addLevel};
        `}
      >
        <PortfolioRow
          deletePortfolioData={props.deletePortfolioData}
          updatePortfolioData={props.updatePortfolioData}
          totals={row.totals}
          subRows={row.subRows}
        />
      </div>
    ));

    const renderTotalsRow = () => (
      <Row css={css`
        font-weight: bolder; 
        border-top: 1px solid ${theme.colors.addLevel};
      `}
      >
        <Cell value="Total" />
        <Cell />
        <Cell />
        <Cell />
        <Cell />
        <Cell dollar value={totals.costBasis} />
        <Cell dollar value={totals.marketValue} />
        <Cell dollar value={totals.gainLoss} />
        <Cell value={totals.growth} percent />
        <Cell dollar value={totals.annualDividend} />
        <Cell value={totals.dividendYield} percent />
        <Cell value={totals.yieldOnCost} percent />
        <Cell dollar value={totals.annualIncome} />
      </Row>
    );

    return (
      <>
        <div>
          <div css={{
            position: 'sticky',
            top: 0,
            background: theme.colors.base,
            zIndex: 1,
          }}
          >
            {renderHeaders()}
          </div>
          <div css={{ background: theme.colors.base }}>
            { allRows }
          </div>
        </div>
        <div css={{ position: 'sticky', bottom: 0, background: theme.colors.base }}>
          {renderTotalsRow()}
          {renderAddNew()}
        </div>
      </>
    );
  };

  return (
    <div css={css`
        flex: 1 1 100%;
        overflow: hidden;
        overflow-y: scroll; 
        display: flex; 
        flex-direction: column; 
        justify-content:space-between;
        color: ${theme.colors.text};
        background: ${theme.colors.base}
    `}
    >
      {Object.keys(portfolio).length !== 0
        ? renderPortfolio() : renderAddNew(true)}
    </div>
  );
};

DividendSheet.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  // iexcloud: PropTypes.shape({
  stocks: PropTypes.objectOf(PropTypes.shape({
    quote: PropTypes.shape({
      companyName: PropTypes.string,
      symbol: PropTypes.string,
      latestPrice: PropTypes.number,
    }),
    dividends: PropTypes.arrayOf(PropTypes.shape({
      ammount: PropTypes.number,
      frequency: PropTypes.string,
    })),
  })).isRequired,
  // error: PropTypes.string,
  // }).isRequired,
  // user: PropTypes.shape({
  portfolio: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  // error: PropTypes.string,
  // }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  updatePortfolioData: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  addPortfolioData: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  deletePortfolioData: PropTypes.func.isRequired,
};

export default DividendSheet;
