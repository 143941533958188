import { ActionTypes } from '../actions';

const initialState = {
  stocks: {},
  error: null,
};

const IEXCloudReducer = (state = initialState, action) => {
  const { symbol } = action.payload || {};
  switch (action.type) {
    case ActionTypes.IEXCLOUD_BATCH:
      return {
        ...state,
        stocks: {
          ...state.stocks,
          ...action.payload,
        },
        error: null,
      };
    case ActionTypes.IEXCLOUD_QUOTE:
      return {
        ...state,
        stocks: {
          ...state.stocks,
          [symbol]: {
            ...state.stocks[symbol],
            quote: action.payload,
          },
        },
        error: null,
      };
    case ActionTypes.IEXCLOUD_DIVIDEND:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default IEXCloudReducer;
