import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import UserReducer from './UserReducer';
import IEXCloudReducer from './IEXCloudReducer';

const rootReducer = combineReducers({
  auth: AuthReducer,
  user: UserReducer,
  iexcloud: IEXCloudReducer,
});

export default rootReducer;
