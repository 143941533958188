export const ROOT_URL = 'https://localhost:5001/api/v1';

export const TOKEN_STRING = 'userAuthToken';

export const ActionTypes = {
  IEXCLOUD_BATCH: 'IEXCLOUD_BATCH',
  IEXCLOUD_QUOTE: 'IEXCLOUD_QUOTE',
  IEXCLOUD_DIVIDEND: 'IEXCLOUD_DIVIDEND',
  IEXCLOUD_ERROR: 'IEXCLOUD_ERROR',

  USER_PORTFOLIO: 'USER_PORTFOLIO',
  USER_ERROR: 'USER_ERROR',

  // AUTH TOKEN
  AUTH_USER: 'AUTH_USER',
  DEAUTH_USER: 'DEAUTH_USER',
  AUTH_ERROR: 'AUTH_ERROR',
};
