export const numberCheck = (num) => {
  const parsed = Number.parseFloat(num, 10);

  if (Number.isNaN(parsed) || !Number.isFinite(parsed)) {
    return 0;
  }

  return parsed;
};


export const rowDataGenerator = (portfolio, stocks) => {
  const totals = {
    quantity: 0,
    costBasis: 0,
    marketValue: 0,
    gainLoss: 0,
    growth: 0,
    annualDividend: 0,
    dividendYield: 0,
    yieldOnCost: 0,
    annualIncome: 0,
  };

  // each stock
  const rows = Object.entries(portfolio).map(([symbol, shares]) => {
    let latestPrice = 0;
    let companyName = '';
    let annualDividend = 0;

    if (stocks[symbol] && stocks[symbol].quote && stocks[symbol].dividends) {
      const { quote, dividends } = stocks[symbol];
      const dividend = dividends[0] || { amount: 0, frequency: '' };
      const { amount, frequency } = dividend;
      const dividendFactor = {
        quarterly: 4,
        'semi-annual': 2,
        monthly: 12,
      };

      latestPrice = numberCheck(quote.latestPrice);
      companyName = quote.companyName;

      const factor = dividendFactor[frequency.toLowerCase()] || 1;
      annualDividend = numberCheck(amount * factor);
    }

    let stockQuantity = 0;
    let weightedAverageBuyPrice = 0;
    const subRows = Object.entries(shares).map(([buyPrice, quantity]) => {
      // if (quantity === undefined) { return; }

      stockQuantity += quantity;
      weightedAverageBuyPrice += buyPrice * quantity;

      const costBasis = buyPrice * quantity;
      const marketValue = latestPrice * quantity;
      const gainLoss = marketValue - costBasis;
      const growth = numberCheck((gainLoss / costBasis) * 100);
      const dividendYield = numberCheck((annualDividend / latestPrice) * 100);
      const yieldOnCost = numberCheck((annualDividend / buyPrice) * 100);
      const annualIncome = annualDividend * quantity;

      return {
        companyName,
        symbol,
        quantity,
        buyPrice: numberCheck(buyPrice),
        latestPrice,
        costBasis,
        marketValue,
        gainLoss,
        growth,
        annualDividend,
        dividendYield,
        yieldOnCost,
        annualIncome,
      };
    });

    weightedAverageBuyPrice = numberCheck(weightedAverageBuyPrice / stockQuantity);

    const costBasis = weightedAverageBuyPrice * stockQuantity;
    const marketValue = latestPrice * stockQuantity;
    const gainLoss = marketValue - costBasis;
    const growth = numberCheck((gainLoss / costBasis) * 100);
    const dividendYield = numberCheck((annualDividend / latestPrice) * 100);
    const yieldOnCost = numberCheck((annualDividend / weightedAverageBuyPrice) * 100);
    const annualIncome = annualDividend * stockQuantity;

    totals.quantity += numberCheck(stockQuantity);
    totals.costBasis += numberCheck(costBasis);
    totals.marketValue += numberCheck(marketValue);
    totals.gainLoss += numberCheck(gainLoss);
    totals.annualDividend += numberCheck(annualDividend * stockQuantity);
    totals.dividendYield += numberCheck(dividendYield * stockQuantity);
    totals.yieldOnCost += numberCheck(yieldOnCost * stockQuantity);
    totals.annualIncome += numberCheck(annualIncome);

    const t = {
      companyName,
      symbol,
      stockQuantity,
      weightedAverageBuyPrice,
      latestPrice,
      costBasis,
      marketValue,
      gainLoss,
      growth,
      annualDividend,
      dividendYield,
      yieldOnCost,
      annualIncome,
    };

    return { subRows, totals: t };
  });

  totals.growth = numberCheck((totals.gainLoss / totals.costBasis) * 100);
  totals.annualDividend = numberCheck(totals.annualDividend / totals.quantity);
  totals.dividendYield = numberCheck(totals.dividendYield / totals.quantity);
  totals.yieldOnCost = numberCheck(totals.yieldOnCost / totals.quantity);

  return { rows, totals };
};
