// import React from 'react';
import styled from '@emotion/styled';

export const Row = styled.div`
    //flex: 1 0 56px;
    height: 64px;

    background: ${({ header, theme }) => (header ? theme.colors.addLevel : 'transparent')};
    :hover {
      background: ${({ theme }) => theme.colors.addLevel};
    }

    display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
    flex-direction: row;
    justify-content: space-evenly;
    //padding: 0 12px;
`;

export const SubRow = styled(Row)`
    //flex: 0 0 24px;
    height: 24px;

    background: ${({ theme }) => theme.colors.addLevel};
    :hover {
      background: ${({ theme }) => theme.colors.addTwoLevels};
    }
`;
