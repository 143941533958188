import React, { useState } from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import PropTypes from 'prop-types';
import { FaTimesCircle } from 'react-icons/fa';
import Cell from './Cell';
import { Row, SubRow } from './Rows';
import { numberCheck } from '../helpers';

const PortfolioRow = (props) => {
  const { totals, subRows } = props;
  console.log(props);
  const theme = useTheme();
  const [tempUpdate, setTempUpdate] = useState('');

  const [hidden, setHidden] = useState(true);

  return (
    <>
      <Row css={css`order: 1`}>
        <Cell value={totals.companyName} />
        <Cell value={totals.symbol} />
        <Cell
          button
          chevron
          value={totals.stockQuantity}
          onClick={() => { setHidden(!hidden); }}
        />
        <Cell dollar value={totals.weightedAverageBuyPrice} />
        <Cell dollar value={totals.latestPrice} />
        <Cell dollar value={totals.costBasis} />
        <Cell dollar value={totals.marketValue} />
        <Cell dollar value={totals.gainLoss} />
        <Cell value={totals.growth} percent />
        <Cell dollar value={totals.annualDividend} />
        <Cell value={totals.dividendYield} percent />
        <Cell value={totals.yieldOnCost} percent />
        <Cell dollar value={totals.annualIncome} />
      </Row>
      {subRows.map((row) => (
        <SubRow
          key={`${row.symbol}-${row.buyPrice}-${row.quantity}`}
          hidden={hidden}
          css={css`order: 2;`}
        >
          <Cell />
          <Cell
            button
            customComponent={(
              <FaTimesCircle size="1.25vw" color={theme.colors.red} />
                )}
            onClick={() => {
              props.deletePortfolioData(row.symbol, row.buyPrice);
            }}
          />
          <Cell customComponent={(
            <input
              type="number"
              css={{
                background: 'transparent',
                flex: 1,
                width: '100%',
                border: 0,
                textAlign: 'center',
              }}
              defaultValue={row.quantity}
              onFocus={() => {
                setTempUpdate(row.quantity);
              }}
              onChange={(e) => {
                const { value } = e.target;
                setTempUpdate(value);
              }}
              onBlur={() => {
                const num = numberCheck(tempUpdate);
                props.updatePortfolioData(row.symbol, row.buyPrice, num);
                setTempUpdate('');
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  e.target.blur();
                }
              }}
            />
              )}
          />
          <Cell dollar value={row.buyPrice} />
          <Cell dollar value={row.latestPrice} />
          <Cell dollar value={row.costBasis} />
          <Cell dollar value={row.marketValue} />
          <Cell dollar value={row.gainLoss} />
          <Cell value={row.growth} percent />
          <Cell dollar value={row.annualDividend} />
          <Cell value={row.dividendYield} percent />
          <Cell value={row.yieldOnCost} percent />
          <Cell dollar value={row.annualIncome} />
        </SubRow>
      ))}
    </>
  );
};

PortfolioRow.propTypes = {
  deletePortfolioData: PropTypes.func.isRequired,
  updatePortfolioData: PropTypes.func.isRequired,
  totals: PropTypes.shape({
    companyName: PropTypes.string,
    symbol: PropTypes.string,
    stockQuantity: PropTypes.number,
    weightedAverageBuyPrice: PropTypes.number,
    latestPrice: PropTypes.number,
    costBasis: PropTypes.number,
    marketValue: PropTypes.number,
    gainLoss: PropTypes.number,
    growth: PropTypes.number,
    annualDividend: PropTypes.number,
    dividendYield: PropTypes.number,
    yieldOnCost: PropTypes.number,
    annualIncome: PropTypes.number,
  }).isRequired,
  subRows: PropTypes.arrayOf(PropTypes.shape({
    companyName: PropTypes.string,
    symbol: PropTypes.string,
    quantity: PropTypes.number,
    buyPrice: PropTypes.number,
    latestPrice: PropTypes.number,
    costBasis: PropTypes.number,
    marketValue: PropTypes.number,
    gainLoss: PropTypes.number,
    growth: PropTypes.number,
    annualDividend: PropTypes.number,
    dividendYield: PropTypes.number,
    yieldOnCost: PropTypes.number,
    annualIncome: PropTypes.number,
  })).isRequired,
};

export default PortfolioRow;
