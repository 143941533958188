import axios from 'axios';
import { ActionTypes, ROOT_URL, TOKEN_STRING } from './Shared';

export function getCurrentAuth() {
  return async (dispatch) => {
    try {
      const token = localStorage.getItem(TOKEN_STRING);

      const response = await axios({
        method: 'get',
        url: `${ROOT_URL}/users/me`,
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data.username) {
        dispatch({ type: ActionTypes.AUTH_USER, payload: { user: response.data } });
      }
    } catch (error) {
      dispatch({ type: ActionTypes.AUTH_ERROR, payload: error.toString() });
    }
  };
}

export function signinUser(params) {
  const { username, password, twoFactorCode } = params;

  return async (dispatch) => {
    try {
      const response = await axios({
        method: 'post',
        url: `${ROOT_URL}/users/authenticate`,
        data: { username, password, twoFactorCode },
      });

      if (response.status === 202) {
        dispatch({ type: ActionTypes.AUTH_NEED_2FA, payload: { user: response.data } });
      } else {
        localStorage.setItem(TOKEN_STRING, response.data.token);
        dispatch({
          type: ActionTypes.AUTH_USER,
          payload: { user: response.data, token: response.data.token },
        });
      }
    } catch (error) {
      dispatch({ type: ActionTypes.AUTH_ERROR, payload: error.toString() });
    }
  };
}


export function signoutUser() {
  return (dispatch) => {
    localStorage.removeItem(TOKEN_STRING);
    dispatch({ type: ActionTypes.DEAUTH_USER });
  };
}
