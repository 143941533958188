export default {
  colors: {
    base: 'rgb(18, 18, 18)',
    elevated1: 'rgb(28, 28, 28)',
    elevated2: 'rgb(34, 34, 34)',
    elevated3: 'rgb(40, 40, 40)',

    addHalfLevel: 'rgba(255,255,255,0.025)',
    addLevel: 'rgba(255,255,255,0.05)',
    addTwoLevels: 'rgba(255,255,255,0.1)',

    text: 'rgb(250,250,250)',
    text2: 'rgb(200,200,200)',
    text3: 'rgb(140,140,140)',

    red: 'rgb(255,69,48)',
    green: 'rgb(48,209,88)',
    blue: 'rgb(10,132,255)',
  },
};
