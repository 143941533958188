import React, { useEffect } from 'react';
import { css } from '@emotion/core';
import { useTheme } from 'emotion-theming';
import { useSelector, useDispatch } from 'react-redux';
import DividendSheet from '../components/DividendSheet';
import {
  getPortfolioData,
  updatePortfolioData,
  addPortfolioData,
  getBatchDataForSymbols,
  deletePortfolioData,
} from '../actions';

const headers = [
  'Company Name',
  'Symbol',
  'Shares',
  'Buy Price',
  'Market Price',
  'Cost Basis',
  'Market Value',
  'Gain/Loss',
  'Growth',
  'Annual Dividend',
  'Dividend Yield',
  'Yield on Cost',
  'Annual Income',
];

const DividendsScreen = () => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const iexcloud = useSelector((state) => state.iexcloud);
  const dispatch = useDispatch();

  const { portfolio } = user;
  const { stocks } = iexcloud;

  useEffect(() => {
    dispatch(getPortfolioData());
  }, []);

  useEffect(() => {
    const needToFetch = [];
    Object.keys(portfolio).forEach((symbol) => {
      if (!stocks[symbol]) {
        needToFetch.push(symbol);
      }
    });

    if (needToFetch.length > 0) {
      dispatch(getBatchDataForSymbols(needToFetch));
    }
  }, [user]);


  return (
    <div css={css`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    `}
    >
      <DividendSheet
        headers={headers}
        portfolio={portfolio}
        stocks={stocks}
        updatePortfolioData={() => { updatePortfolioData(); }}
        deletePortfolioData={() => { deletePortfolioData(); }}
        addPortfolioData={() => { addPortfolioData(); }}
      />
    </div>
  );
};

DividendsScreen.propTypes = {
};

export default DividendsScreen;
