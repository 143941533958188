import axios from 'axios';
import { ActionTypes } from './Shared';

const IEXCLOUD_BASE_URL = process.env.NODE_ENV === 'development'
  ? 'https://sandbox.iexapis.com/v1' : 'https://cloud.iexapis.com/v1';

const IEXCLOUD_TOKEN = process.env.NODE_ENV === 'development'
  ? process.env.IEXCLOUD_TOKEN_SANDBOX : process.env.IEXCLOUD_TOKEN_CLOUD;

export function getBatchDataForSymbols(symbols) {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: 'get',
        url: `${IEXCLOUD_BASE_URL}/stock/market/batch?symbols=${symbols.join(',')}&types=quote,dividends&range=6m&token=${IEXCLOUD_TOKEN}`,
        // headers: { Authorization: `Bearer ${token}` },
      });

      dispatch({ type: ActionTypes.IEXCLOUD_BATCH, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.IEXCLOUD_ERROR, payload: error.toString() });
    }
  };
}
export function getQuoteForSymbol(symbol) {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: 'get',
        url: `${IEXCLOUD_BASE_URL}/stock/${symbol}/quote?token=${IEXCLOUD_TOKEN}`,
        // headers: { Authorization: `Bearer ${token}` },
      });

      dispatch({ type: ActionTypes.IEXCLOUD_QUOTE, payload: response.data });
    } catch (error) {
      dispatch({ type: ActionTypes.IEXCLOUD_ERROR, payload: error.toString() });
    }
  };
}

export function getDividendForSymbol(symbol) {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: 'get',
        url: `${IEXCLOUD_BASE_URL}/stock/${symbol}/dividends/next?token=${IEXCLOUD_TOKEN}`,
        // headers: { Authorization: `Bearer ${token}` },
      });
      //      console.log(response);

      dispatch({ type: ActionTypes.IEXCLOUD_DIVIDEND, payload: { user: response.data } });
    } catch (error) {
      dispatch({ type: ActionTypes.IEXCLOUD_ERROR, payload: error.toString() });
    }
  };
}
