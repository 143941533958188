import React from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';

import { withTheme } from 'emotion-theming';
import Routes from '../components/Routes';

const App = () => (
  <Router>
    <Routes />
  </Router>
);

export default withTheme(App);
