import React from 'react';
import {
  Switch, Route, Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Dividends from '../screens/Dividends';

const Routes = (props) => {
  const ProtectedRoute = ({ children, ...rest }) => {
    const { auth } = props;
    const { authenticated } = auth;
    const isAuth = authenticated; // || process.env.NODE_ENV === 'development';

    return (
      <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        render={({ location }) => (isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        ))}
      />
    );
  };

  ProtectedRoute.propTypes = {
    children: PropTypes.node.isRequired,
  };

  const FallBack = () => <div>URL Not Found</div>;

  return (
    <Switch>
      <Route
        exact
        path="/"
      >
        <Dividends />
      </Route>

      <ProtectedRoute
        exact
        path="/protected"
      >
        <div />
        {/* <LoginScreen /> */}
      </ProtectedRoute>

      <Route>
        <FallBack />
      </Route>

    </Switch>
  );
};

Routes.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.object,
    authenticated: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,

};

const mapStateToProps = (state) => (
  {
    auth: state.auth,
  }
);

export default connect(mapStateToProps, null)(Routes);
