import { ActionTypes } from '../actions';

const initialState = {
  user: {},
  token: null,
  authenticated: false,
  error: null,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.AUTH_USER:
      return {
        user: action.payload.user,
        token: action.payload.token ? action.payload.token : state.token,
        authenticated: true,
      };
    case ActionTypes.AUTH_NEED_2FA:
      return {
        user: action.payload.user,
        authenticated: false,
        need2fa: true,
      };
    case ActionTypes.DEAUTH_USER:
      return {
        user: {},
        authenticated: false,
        need2fa: false,
      };
    case ActionTypes.AUTH_ERROR:
      return {
        ...state,
        authenticated: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default AuthReducer;
