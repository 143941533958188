import { ActionTypes } from './Shared';

const STORAGE_TOKEN = 'portfolioString';
class PortfolioData {
    static instance = new PortfolioData();

    constructor() {
      this.data = JSON.parse(localStorage.getItem(STORAGE_TOKEN) || '{}');
    }

   getPortfolioData = () => (this.data);

   updatePortfolioData = (s, price, quantity) => {
     if (price < 0 || quantity < 0) { return this.data; }

     const symbol = s.toUpperCase();
     if (!this.data[symbol]) {
       this.data[symbol] = {};
     }

     if (this.data[symbol][price] === null || this.data[symbol][price] === undefined) {
       return this.data;
     }

     this.data[symbol][price] = quantity;

     localStorage.setItem(STORAGE_TOKEN, JSON.stringify(this.data));
     return this.data;
   }

    addPortfolioData = (s, price, quantity) => {
      if (price < 0 || quantity < 0) { return this.data; }

      const symbol = s.toUpperCase();
      if (!this.data[symbol]) {
        this.data[symbol] = {};
      }

      if (this.data[symbol][price] === null || this.data[symbol][price] === undefined) {
        this.data[symbol][price] = quantity;
      } else {
        this.data[symbol][price] += quantity;
      }

      localStorage.setItem(STORAGE_TOKEN, JSON.stringify(this.data));
      return this.data;
    }

   deletePortfolioData = (symbol, price) => {
     if (price === undefined || price < 0) { return this.data; }

     if (price !== undefined) {
       delete this.data[symbol][price];

       if (Object.keys(this.data[symbol]).length === 0) {
         delete this.data[symbol];
       }
     } else {
       delete this.data[symbol];
     }

     localStorage.setItem(STORAGE_TOKEN, JSON.stringify(this.data));
     return this.data;
   }
}

export function getPortfolioData() {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.USER_PORTFOLIO,
        payload: PortfolioData.instance.getPortfolioData(),
      });
    } catch (error) {
      dispatch({ type: ActionTypes.USER_ERROR, payload: error.toString() });
    }
  };
}

export function updatePortfolioData(symbol, price, quantity) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.USER_PORTFOLIO,
        payload: PortfolioData.instance.updatePortfolioData(symbol, price, quantity),
      });
    } catch (error) {
      dispatch({ type: ActionTypes.USER_ERROR, payload: error.toString() });
    }
  };
}

export function addPortfolioData(symbol, price, quantity) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.USER_PORTFOLIO,
        payload: PortfolioData.instance.addPortfolioData(symbol, price, quantity),
      });
    } catch (error) {
      dispatch({ type: ActionTypes.USER_ERROR, payload: error.toString() });
    }
  };
}

export function deletePortfolioData(symbol, price) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.USER_PORTFOLIO,
        payload: PortfolioData.instance.deletePortfolioData(symbol, price),
      });
    } catch (error) {
      dispatch({ type: ActionTypes.USER_ERROR, payload: error.toString() });
    }
  };
}
